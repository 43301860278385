import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '@/store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Auth/Login.vue')
  },
  {
    path: '/products',
    name: 'Productos',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/Index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/products/create',
    name: 'Crear Producto',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/create.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/products/edit/:id',
    name: 'Editar Producto',
    component: () => import(/* webpackChunkName: "about" */ '../views/products/edit.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/clients',
    name: 'Clientes',
    component: () => import(/* webpackChunkName: "about" */ '../views/clients/Index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/clients/create',
    name: 'Crear Cliente',
    component: () => import(/* webpackChunkName: "about" */ '../views/clients/create.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/clients/edit/:id',
    name: 'Actualizar Cliente',
    component: () => import(/* webpackChunkName: "about" */ '../views/clients/edit.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/partners',
    name: 'Partners',
    component: () => import(/* webpackChunkName: "about" */ '../views/partners/Index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/partners/edit/:id',
    name: 'Actualizar Partner',
    component: () => import(/* webpackChunkName: "about" */ '../views/partners/edit.vue'),
    meta: {
      requiresAuth: true
    }
  },
  /* Rutas Partner 1 */
  {
    path: '/partner-client',
    name: 'Clientes',
    component: () => import(/* webpackChunkName: "about" */ '../views/partner-1/partner-client/Index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/partner-client/create',
    name: 'Crear Cliente',
    component: () => import(/* webpackChunkName: "about" */ '../views/partner-1/partner-client/create.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/partner-client/edit/:id',
    name: 'Actualizar Cliente',
    component: () => import(/* webpackChunkName: "about" */ '../views/partner-1/partner-client/edit.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/partner-tracking-bb/edit/:id',
    name: 'Tracking Edit',
    component: () => import(/* webpackChunkName: "about" */ '../views/partner-1/partner-order/edit'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/partner-tracking-bc/edit/:id',
    name: 'Tracking Edit',
    component: () => import(/* webpackChunkName: "about" */ '../views/partner-1/partner-order/edit'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/partner-order',
    name: 'Pedidos',
    component: () => import(/* webpackChunkName: "about" */ '../views/partner-1/partner-order/Index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/partner-order/create',
    name: 'Crear Pedido',
    component: () => import(/* webpackChunkName: "about" */ '../views/partner-1/partner-order/create.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/partner-order/edit/:id',
    name: 'Actualizar Pedido',
    component: () => import(/* webpackChunkName: "about" */ '../views/partner-1/partner-order/edit.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/partner-tracking-bb',
    name: 'Partner Tracking B2B',
    component: () => import(/* webpackChunkName: "about" */ '../views/partner-1/partner-tracking-bb/Index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/partner-tracking-bc',
    name: 'Partner Tracking B2C',
    component: () => import(/* webpackChunkName: "about" */ '../views/partner-1/partner-tracking-bc/Index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/partner-list-price',
    name: 'Partner List Price',
    component: () => import(/* webpackChunkName: "about" */ '../views/partner-list-price/Index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/partner-list-price/create',
    name: 'Crear Lista',
    component: () => import(/* webpackChunkName: "about" */ '../views/partner-list-price/create.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/partner-list-price/edit/:id',
    name: 'Actualizar Lista',
    component: () => import(/* webpackChunkName: "about" */ '../views/partner-list-price/edit.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/partner-report',
    name: 'Partner Report',
    component: () => import(/* webpackChunkName: "about" */ '../views/partner-1/partner-report/Index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/partner-facturacion/edit/:id',
    name: 'Facturación Edit',
    component: () => import(/* webpackChunkName: "about" */ '../views/partner-1/partner-order/edit'),
    meta: {
      requiresAuth: true
    }
  },
  /* ------------------------------------------------------------------------------------------------------- */
  /* Rutas Partner 2 */
  {
    path: '/partner2-client',
    name: 'Clientes',
    component: () => import(/* webpackChunkName: "about" */ '../views/partner-2/partner-client/Index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/partner2-client/create',
    name: 'Crear Cliente',
    component: () => import(/* webpackChunkName: "about" */ '../views/partner-2/partner-client/create.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/partner2-client/edit/:id',
    name: 'Actualizar Cliente',
    component: () => import(/* webpackChunkName: "about" */ '../views/partner-2/partner-client/edit.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/partner2-tracking-bb/edit/:id',
    name: 'Tracking Edit',
    component: () => import(/* webpackChunkName: "about" */ '../views/partner-2/partner-order/edit'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/partner2-tracking-bc/edit/:id',
    name: 'Tracking Edit',
    component: () => import(/* webpackChunkName: "about" */ '../views/partner-2/partner-order/edit'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/partner2-order',
    name: 'Pedidos',
    component: () => import(/* webpackChunkName: "about" */ '../views/partner-2/partner-order/Index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/partner2-order/create',
    name: 'Crear Pedido',
    component: () => import(/* webpackChunkName: "about" */ '../views/partner-2/partner-order/create.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/partner2-order/edit/:id',
    name: 'Actualizar Pedido',
    component: () => import(/* webpackChunkName: "about" */ '../views/partner-2/partner-order/edit.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/partner2-tracking-bb',
    name: 'Partner Tracking B2B',
    component: () => import(/* webpackChunkName: "about" */ '../views/partner-2/partner-tracking-bb/Index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/partner2-tracking-bc',
    name: 'Partner Tracking B2C',
    component: () => import(/* webpackChunkName: "about" */ '../views/partner-2/partner-tracking-bc/Index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/partner2-report',
    name: 'Partner Report',
    component: () => import(/* webpackChunkName: "about" */ '../views/partner-2/partner-report/Index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/partner2-facturacion/edit/:id',
    name: 'Facturación Edit',
    component: () => import(/* webpackChunkName: "about" */ '../views/partner-2/partner-order/edit'),
    meta: {
      requiresAuth: true
    }
  },
  /* ------------------------------------------------------------------------------------------------------- */
  {
    path: '/businesses',
    name: 'Clientes',
    component: () => import(/* webpackChunkName: "about" */ '../views/businesses/Index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/businesses/create',
    name: 'Crear Empresa',
    component: () => import(/* webpackChunkName: "about" */ '../views/businesses/create.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/businesses/edit/:id',
    name: 'Actualizar Empresa',
    component: () => import(/* webpackChunkName: "about" */ '../views/businesses/edit.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pedidos',
    name: 'Pedidos',
    component: () => import(/* webpackChunkName: "about" */ '../views/pedidos/Index'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pedidos/create',
    name: 'Pedidos Create',
    component: () => import(/* webpackChunkName: "about" */ '../views/pedidos/create'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pedidos/edit/:id',
    name: 'Pedidos Edit',
    component: () => import(/* webpackChunkName: "about" */ '../views/pedidos/edit'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pedidos-manual',
    name: 'Pedidos',
    component: () => import(/* webpackChunkName: "about" */ '../views/pedidos-manual/Index'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pedidos-manual/create',
    name: 'Pedidos Create',
    component: () => import(/* webpackChunkName: "about" */ '../views/pedidos-manual/create'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pedidos-manual/edit/:id',
    name: 'Pedidos Edit',
    component: () => import(/* webpackChunkName: "about" */ '../views/pedidos-manual/edit'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/manual-tracking',
    name: 'Tracking Manual',
    component: () => import(/* webpackChunkName: "about" */ '../views/tracking-manual/Index'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/manual-tracking2',
    name: 'Tracking 2 Manual',
    component: () => import(/* webpackChunkName: "about" */ '../views/tracking2-manual/Index'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/manual-tracking/edit/:id',
    name: 'Tracking Edit Manual',
    component: () => import(/* webpackChunkName: "about" */ '../views/pedidos-manual/edit'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/tracking',
    name: 'Tracking',
    component: () => import(/* webpackChunkName: "about" */ '../views/tracking/Index'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/tracking2',
    name: 'Tracking 2',
    component: () => import(/* webpackChunkName: "about" */ '../views/tracking2/Index'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/tracking/edit/:id',
    name: 'Tracking Edit',
    component: () => import(/* webpackChunkName: "about" */ '../views/pedidos/edit'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/facturacion',
    name: 'Facturación',
    component: () => import(/* webpackChunkName: "about" */ '../views/facturacion/Index'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/facturacion/edit/:id',
    name: 'Facturación Edit',
    component: () => import(/* webpackChunkName: "about" */ '../views/pedidos/edit'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/web/facturacion/edit/:id',
    name: 'Facturación Edit',
    component: () => import(/* webpackChunkName: "about" */ '../views/pedidos-web/edit'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reporte',
    name: 'Reporte',
    component: () => import(/* webpackChunkName: "about" */ '../views/reporte/Index'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pedidos-web',
    name: 'Pedidos Web',
    component: () => import(/* webpackChunkName: "about" */ '../views/pedidos-web/Index'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/users/web',
    name: 'Usuarios Web',
    component: () => import(/* webpackChunkName: "about" */ '../views/users-web/Index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/users',
    name: 'Usuarios',
    component: () => import(/* webpackChunkName: "about" */ '../views/users/Index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/users/create',
    name: 'Crear Usuario',
    component: () => import(/* webpackChunkName: "about" */ '../views/users/create.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/users/edit/:id',
    name: 'Editar Usuario',
    component: () => import(/* webpackChunkName: "about" */ '../views/users/edit.vue'),
    meta: {
      requiresAuth: true
    }
  },
  //listas
  {
    path: '/listas',
    name: 'Listas',
    component: () => import(/* webpackChunkName: "about" */ '../views/listas/Index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/listas/create',
    name: 'Crear Lista',
    component: () => import(/* webpackChunkName: "about" */ '../views/listas/create.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/listas/edit/:id',
    name: 'Actualizar Lista',
    component: () => import(/* webpackChunkName: "about" */ '../views/listas/edit.vue'),
    meta: {
      requiresAuth: true
    }
  },

  //listas
  {
    path: '/bodegas',
    name: 'bodegas',
    component: () => import(/* webpackChunkName: "about" */ '../views/bodegas/Index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bodegas/create',
    name: 'Crear Bodega',
    component: () => import(/* webpackChunkName: "about" */ '../views/bodegas/create.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bodegas/edit/:id',
    name: 'Actualizar Bodega',
    component: () => import(/* webpackChunkName: "about" */ '../views/bodegas/edit.vue'),
    meta: {
      requiresAuth: true
    }
  },

    {
        path: '/marcas',
        name: 'marcas',
        component: () => import(/* webpackChunkName: "about" */ '../views/marcas/Index.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/marcas/create',
        name: 'Crear Marca',
        component: () => import(/* webpackChunkName: "about" */ '../views/marcas/create.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/marcas/edit/:id',
        name: 'Actualizar Marca',
        component: () => import(/* webpackChunkName: "about" */ '../views/marcas/edit.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/channels',
        name: 'Canales',
        component: () => import(/* webpackChunkName: "about" */ '../views/channels/Index.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/channels/create',
        name: 'Crear Canal',
        component: () => import(/* webpackChunkName: "about" */ '../views/channels/create.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/channels/edit/:id',
        name: 'Actualizar Canal',
        component: () => import(/* webpackChunkName: "about" */ '../views/channels/edit.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/subchannels',
        name: 'Subcanales',
        component: () => import(/* webpackChunkName: "about" */ '../views/subchannels/Index.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/subchannels/create',
        name: 'Crear Subcanal',
        component: () => import(/* webpackChunkName: "about" */ '../views/subchannels/create.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/subchannels/edit/:id',
        name: 'Actualizar Subcanal',
        component: () => import(/* webpackChunkName: "about" */ '../views/subchannels/edit.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/coupons',
        name: 'coupons',
        component: () => import(/* webpackChunkName: "about" */ '../views/coupons/Index.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/coupons/create',
        name: 'CrearCupon',
        component: () => import(/* webpackChunkName: "about" */ '../views/coupons/create.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/coupons/edit/:id',
        name: 'ActualizarCupon',
        component: () => import(/* webpackChunkName: "about" */ '../views/coupons/edit.vue'),
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/categorias',
        name: 'categorias',
        component: () => import(/* webpackChunkName: "about" */ '../views/categorias/Index.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/categorias/create',
        name: 'Crear Categoría',
        component: () => import(/* webpackChunkName: "about" */ '../views/categorias/create.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/categorias/edit/:id',
        name: 'Actualizar Categoría',
        component: () => import(/* webpackChunkName: "about" */ '../views/categorias/edit.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/banners',
        name: 'Banners',
        component: () => import(/* webpackChunkName: "about" */ '../views/banners/Index.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/banners/create',
        name: 'Crear Banner',
        component: () => import(/* webpackChunkName: "about" */ '../views/banners/create.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/banners/edit/:id',
        name: 'Actualizar Banner',
        component: () => import(/* webpackChunkName: "about" */ '../views/banners/edit.vue'),
        meta: {
            requiresAuth: true
        }
    },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
