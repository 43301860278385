import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    login(email, password) {
        const user = {email, password};
        return axios.post(`${ENDPOINT_PATH}/login`, user);
    }
};
