<template>
  <v-app :style="{background: $vuetify.theme.themes[theme].background}">
    <AppBar v-if="isLoggedIn"/>
    <Sidebar v-if="isLoggedIn"/>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Sidebar from "@/components/layout/Sidebar";
import AppBar from "@/components/layout/AppBar";

export default {
  name: 'App',

  components: {
    Sidebar,
    AppBar
  },
  computed: {
    isLoggedIn : function(){
      return this.$store.getters.isLoggedIn
    },
    theme(){
      return this.isLoggedIn ? 'dark' : 'light'
    }
  },
  data: () => ({

  }),
  created: function () {
    this.$http.interceptors.response.use(undefined, function (err) {
      return new Promise(function (resolve, reject) {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch(logout)
        }
        throw err;
      });
    });
  }
};
</script>
