<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-navigation-drawer permanent clipped app>
        <v-list dense>
            <v-list-item-group
                    v-model="selectedItem"
                    color="secondary">
                <template v-for="item in items"
                          v-if="item.roles.includes(userSession.role)">
                    <v-list-item
                            v-if="!item.children"
                            :key="item.title"
                            :to="item.link" link>
                        <v-list-item-content>
                            <v-list-item-title v-text="item.title" class="body-1 ml-4 mt-2 mb-2"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <!--<v-list-group
                            :key="item.text"
                            no-action
                            v-if="item.children">
                        <v-list-item
                                v-for="itemChildren in item.children"
                                :key="itemChildren.title"
                                :to="itemChildren.link" link>
                            <v-list-item-content>
                                <v-list-item-title v-text="itemChildren.title" class="body-1 ml-4 mt-2 mb-2"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                    </v-list-group>-->

                    <v-list-group
                            v-if="item.children"
                            :key="item.text" no-action>
                        <template v-slot:activator>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title v-text="item.title" class="body-1 mt-2 mb-2"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>

                        <v-list-item
                                v-for="itemChildren in item.children"
                                :to="itemChildren.link"
                                :key="itemChildren.title"
                                v-if="itemChildren.roles.includes(userSession.role)"
                        >
                            <v-list-item-title v-text="itemChildren.title" />
                        </v-list-item>

                    </v-list-group>
                </template>
                <!--<v-list-item
                    v-for="item in items"
                    :key="item.title"
                    :to="item.link" link
                    v-if="item.roles.includes(userSession.role)"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title" class="body-1 ml-4 mt-2 mb-2"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>-->

            </v-list-item-group>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    // @ is an alias to /src


    export default {
        computed: {
            userSession: function () {
                return this.$store.getters.userSession
            },
        },
        name: 'Sidebar',
        components: {},
        data: () => ({
            selectedItem: 1,
            items: [
                {title: 'Dashboard', icon: 'mdi-help-box', link: '/', roles: ['admin']},
                {
                    title: 'Configuración', icon: 'mdi-help-box', roles: ['saco', 'admin'],
                    children: [
                        {title: 'Usuarios', icon: 'mdi-help-box', link: '/users', roles: ['bitel', 'saco', 'admin']},
                        {title: 'Productos', icon: 'mdi-image', link: '/products', roles: ['bitel', 'saco', 'admin']},
                        {title: 'Bodegas', icon: 'mdi-help-box', link:'/bodegas', roles: ['admin']},
                        {title: 'Marcas', icon: 'mdi-help-box', link:'/marcas', roles: ['admin']},
                        {title: 'Categorías', icon: 'mdi-help-box', link:'/categorias', roles: ['admin']},
                        {title: 'Cupones', icon: 'mdi-help-box', link:'/coupons', roles: ['admin']},
                        {title: 'Banners', icon: 'mdi-help-box', link:'/banners', roles: ['admin']},
                        {title: 'Canales', icon: 'mdi-help-box', link:'/channels', roles: ['admin']},
                        {title: 'Subcanales', icon: 'mdi-help-box', link:'/subchannels', roles: ['admin']},
                        {title: 'Listas de precio', icon: 'mdi-help-box', link:'/listas', roles: ['admin']},
                        {title: 'Listas de precio Partner', icon: 'mdi-help-box', link:'/partner-list-price', roles: ['admin']},
                        {title: 'Partners', icon: 'mdi-help-box', link: '/partners', roles: ['admin']},
                        {title: 'Clientes', icon: 'mdi-help-box', link: '/businesses', roles: ['admin']},
                        {title: 'Clientes Web', icon: 'mdi-help-box', link: '/users/web', roles: ['admin']},
                    ]
                },
                {
                    title: 'Admin./Oper.', icon: 'mdi-help-box', roles: ['saco', 'admin'],
                    children: [
                        // {title: 'Clientes', icon: 'mdi-help-box', link: '/clients', roles: ['admin', 'bitel', 'bitel']},
                        {title: 'Facturación', icon: 'mdi-help-box', link: '/facturacion', roles: ['bitel', 'saco', 'admin']},
                    ]
                },
                {
                    title: 'Saco', icon: 'mdi-help-box', roles: ['saco', 'admin','partner'],
                    children: [
                        {
                            title: 'Pedidos Web',
                            icon: 'mdi-help-box',
                            link: '/pedidos-web',
                            roles: ['bitel', 'saco', 'admin']
                        },
                        {
                            title: 'Pedidos Manuales',
                            icon: 'mdi-help-box',
                            link: '/pedidos-manual',
                            roles: ['bitel', 'saco', 'admin','partner']
                        },
                        {
                            title: 'Tracking Mayorista',
                            icon: 'mdi-help-box',
                            link: '/manual-tracking',
                          roles: ['bitel', 'saco', 'admin','partner']
                        },
                        {
                            title: 'Tracking Minorista',
                            icon: 'mdi-help-box',
                            link: '/manual-tracking2',
                          roles: ['bitel', 'saco', 'admin','partner']
                        },
                        {
                            title: 'Reporte',
                            icon: 'mdi-help-box',
                            link: '/reporte',
                          roles: ['bitel', 'saco', 'admin','partner']
                        },
                    ]
                },
                {
                    title: 'Partner Bitel', icon: 'mdi-help-box', roles: ['bitel', 'saco', 'admin'],
                    children: [
                        {title: 'Clientes', icon: 'mdi-help-box', link: '/clients', roles: ['admin', 'bitel', 'bitel']},
                        {
                            title: 'Pedidos',
                            icon: 'mdi-help-box',
                            link: '/pedidos',
                            roles: ['bitel', 'saco', 'admin','partner']
                        },
                        {
                            title: 'Tracking B2B',
                            icon: 'mdi-help-box',
                            link: '/tracking',
                            roles: ['bitel', 'saco', 'admin','partner']
                        },
                        {
                            title: 'Tracking B2C',
                            icon: 'mdi-help-box',
                            link: '/tracking2',
                            roles: ['bitel', 'saco', 'admin','partner']
                        },
                        {
                            title: 'Reporte',
                            icon: 'mdi-help-box',
                            link: '/reporte',
                            roles: ['bitel', 'saco', 'admin','partner']
                        },
                    ]
                },
                {
                    title: 'Partner 1', icon: 'mdi-help-box', roles: ['saco', 'admin','partner','P1-Supervisor','P1-EjecutivoVentas'],
                    children: [
                        {
                            title: 'Clientes', 
                            icon: 'mdi-help-box', 
                            link: '/partner-client', 
                            roles: ['admin', 'saco', 'P1-Supervisor']
                        },
                        {
                            title: 'Pedidos',
                            icon: 'mdi-help-box',
                            link: '/partner-order',
                            roles: ['bitel', 'saco', 'admin','partner','P1-Supervisor','P1-EjecutivoVentas']
                        },
                        {
                            title: 'Tracking B2B',
                            icon: 'mdi-help-box',
                            link: '/partner-tracking-bb',
                            roles: ['bitel', 'saco', 'admin','partner','P1-Supervisor','P1-EjecutivoVentas']
                        },
                        {
                            title: 'Tracking B2C',
                            icon: 'mdi-help-box',
                            link: '/partner-tracking-bc',
                            roles: ['bitel', 'saco', 'admin','partner','P1-Supervisor','P1-EjecutivoVentas']
                        },
                        {
                            title: 'Reporte',
                            icon: 'mdi-help-box',
                            link: '/partner-report',
                            roles: ['bitel', 'saco', 'admin','partner','P1-Supervisor','P1-EjecutivoVentas']
                        },
                    ]
                },
                {
                    title: 'Partner 2', icon: 'mdi-help-box', roles: ['saco', 'admin','partner','P2-Supervisor','P2-EjecutivoVentas'],
                    children: [
                        {
                            title: 'Clientes', 
                            icon: 'mdi-help-box', 
                            link: '/partner2-client', 
                            roles: ['admin', 'saco', 'P2-Supervisor']
                        },
                        {
                            title: 'Pedidos',
                            icon: 'mdi-help-box',
                            link: '/partner2-order',
                            roles: ['bitel', 'saco', 'admin','partner','P2-Supervisor','P2-EjecutivoVentas']
                        },
                        {
                            title: 'Tracking B2B',
                            icon: 'mdi-help-box',
                            link: '/partner2-tracking-bb',
                            roles: ['bitel', 'saco', 'admin','partner','P2-Supervisor','P2-EjecutivoVentas']
                        },
                        {
                            title: 'Tracking B2C',
                            icon: 'mdi-help-box',
                            link: '/partner2-tracking-bc',
                            roles: ['bitel', 'saco', 'admin','partner','P2-Supervisor','P2-EjecutivoVentas']
                        },
                        {
                            title: 'Reporte',
                            icon: 'mdi-help-box',
                            link: '/partner2-report',
                            roles: ['bitel', 'saco', 'admin','partner','P2-Supervisor','P2-EjecutivoVentas']
                        },
                    ]
                },
            ],
            right: null,
        }),
    }
</script>
