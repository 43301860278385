import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import router from './router'
import store from './store'
import Axios from 'axios'
import vuetify from './plugins/vuetify';
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import DatetimePicker from 'vuetify-datetime-picker'
import VueApexCharts from 'vue-apexcharts';
import moment from 'moment';

import './css/app.css';
Vue.config.productionTip = false
Vue.prototype.$http = Axios;
const token = localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token
}
Vue.use(Vuex);
Vue.use(DatetimePicker);
Vue.use(VueApexCharts);

Vue.component('apexchart', VueApexCharts);

Vue.directive('number', {
    update(el, binding, vnode) {
        let childData = el.querySelectorAll("input")[0];
        const event = new Event('input', {bubbles: true});
        childData.addEventListener('keyup', function (evt) {
            childData.value = childData.value.replace(/[a-zA-Z]+/g, '').replace('.', '');
            childData.dispatchEvent(event);
        });
    },
});

Vue.directive('intNumber', {
    update(el, binding, vnode) {
        let childData = el.querySelectorAll("input")[0];
        const event = new Event('input', {bubbles: true});
        childData.addEventListener('keyup', function (evt) {
            if (/\D/g.test(childData.value)) {
                childData.value = childData.value.replace(/\D/g, '');
                childData.dispatchEvent(event);
            }
        });
    },
});

Vue.filter('date', function(value, format = null) {
    if (value) {
        return moment(String(value)).format(format ? format : 'DD/MM/YYYY')
    }
});

Vue.filter('currency', function (value, currency) {
    if (!currency) {
        currency = 'S/';
    }
    let val = (value/1).toFixed(2);
    return currency + (val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
});

new Vue({
  router,
  store,
  beforeCreate() { this.$store.commit('initialiseStore');},
  vuetify,
  render: h => h(App)
}).$mount('#app')
