import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    searchProducts(search,client_id,type_transaction) {
        return axios.post(`${ENDPOINT_PATH}/products/search`,{
            search: search,
            client_id:client_id,
            type_transaction:type_transaction,
        });
    },
    searchProductsBusiness(search,business_id,type_transaction) {
        return axios.post(`${ENDPOINT_PATH}/products-business/search`,{
            search: search,
            business_id:business_id,
            type_transaction:type_transaction,
        });
    },
    productsDatatable(data) {
        return axios.post(`${ENDPOINT_PATH}/products/datatable`, data);
    },
    uploadPhoto(image_base64) {
        const file = {file : image_base64};
        return axios.post(`${ENDPOINT_PATH}/products/upload-photo`, file);
    },
    storeProduct(data) {
        return axios.post(`${ENDPOINT_PATH}/products`, data);
    },
    updateProduct(data) {
        return axios.post(`${ENDPOINT_PATH}/products/update`, data);
    },
    getProduct(product_id) {
        return axios.get(`${ENDPOINT_PATH}/products/${product_id}`);
    },
    autocompleteSearch(search) {
        if (!search) {
            search = '';
        }
        return axios.get(`${ENDPOINT_PATH}/products/autocomplete/search?search=${search}`);
    },
};
