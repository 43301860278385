import Vue from 'vue'
import Vuex from 'vuex'
import auth from "@/services/auth";
import axios from "axios";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user : {}
  },
  mutations: {
    initialiseStore(state) {
      let user = localStorage.getItem('user');
      if (user) {
        state.user = JSON.parse(user);
      }
    },
    auth_request(state){
      state.status = 'loading'
    },
    auth_success(state, data){
      state.status = 'success'
      state.token = data.token
      localStorage.setItem('user', JSON.stringify(data.user));
      state.user = data.user
    },
    auth_error(state){
      state.status = 'error'
    },
    logout(state){
      state.status = ''
      state.token = ''
    },
  },
  actions: {
    login({commit}, user){
      return new Promise((resolve, reject) => {
        commit('auth_request')
        auth.login(user.email,user.password).then(response => {
          console.log(response.data);
          const token = response.data.token;
          const user = response.data.user;
          localStorage.setItem('token', token)
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
          console.log('user_send',user);
          commit('auth_success', {token,user})
          resolve(response)
        }, error => {
          commit('auth_error')
          localStorage.removeItem('token')
          reject(error)
        })
      })
    },
    logout({commit}){
      return new Promise((resolve, reject) => {
        commit('logout')
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    }
  },
  getters : {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    userSession: state => state.user,
  },
  modules: {
  }
})
