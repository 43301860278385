<template>
  <v-app-bar
      app
      color="primary"
      dark
      clipped-left
  >
    <div class="d-flex align-center">

      <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://i.imgur.com/nKCE7Jr.png"
          width="150"
      />
    </div>

    <v-spacer></v-spacer>
    <div class="mr-4">
      <div class="subtitle-2 text-right">Bienvenido</div>
      <div class="subtitle-1 text-right">{{userSession.name}}</div>
    </div>
    <v-menu
        bottom
        origin="center center"
        transition="scale-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-avatar
            v-bind="attrs"
            v-on="on"
            color="accent"
            size="44">
          <span class="white--text overline">TC</span>
        </v-avatar>
      </template>

      <v-list>
        <v-list-item>
          <v-list-item-title v-on:click="logout()">Cerrar sesión</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'AppBar',
  components: {},
  computed: {
    userSession: function () {
      return this.$store.getters.userSession
    },
  },
  data: () => ({}),
  methods: {
    logout() {
      this.$store.dispatch('logout')
          .then(() => {
            this.$router.push('/login')
          })
    }
  }
}
</script>
<style>
    .v-sheet.v-app-bar.v-toolbar {
        z-index: 3002;
    }
</style>
