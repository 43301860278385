<template>
    <v-container fluid v-if="userSession.role == 'admin'">
        <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                color="primary"
                size="64"
            ></v-progress-circular>
        </v-overlay>
        <v-snackbar v-model="snackbar" :timeout="2000">
            {{ textError }}
            <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <v-row>
            <v-col>
                <h2>Dashboard</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="3">
                <label>Rango de fechas</label>
                <date-range-picker
                    ref="picker"
                    opens="right"
                    class="form-control"
                    :locale-data="{
                                direction: 'ltr',
                                format: 'dd/mm/yyyy',
                                separator: ' - ',
                                applyLabel: 'Aplicar',
                                cancelLabel: 'Cancelar',
                                weekLabel: 'W',
                                customRangeLabel: 'Rango personalizado',
                                daysOfWeek: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vie', 'Sab'],
                                monthNames: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Set', 'Oct', 'Nov', 'Dic'],
                                firstDay: 0
                            }"
                    v-model="dateRange"
                    return-object
                    @update="getData">
                    <template v-slot:input="picker" style="min-width: 350px;">
                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                    </template>
                </date-range-picker>
            </v-col>
            <v-col cols="12" md="3">
                <label>Filtra por Categoría</label>
                <v-select
                    v-model="categoriaId"
                    :items="categorias"
                    @change="getData()"
                    item-text="nombre"
                    item-value="id"
                    label="Categorías"
                    outlined
                    solo
                ></v-select>
            </v-col>
            <v-col cols="12" md="3">
                <label>Filtra por Marca</label>
                <v-select
                    v-model="marcaId"
                    :items="marcas"
                    @change="getData()"
                    item-text="nombre"
                    item-value="id"
                    label="Marcas"
                    outlined
                    solo
                ></v-select>
            </v-col>
            <v-col cols="12" md="3">
                <label>Filtra por Producto</label>
                <v-autocomplete
                    :clearable="true"
                    :items="products"
                    :search-input.sync="search"
                    :loading="isLoadingSearch"
                    v-model="productSearch"
                    item-text="text_search"
                    item-value="id"
                    placeholder="Filtra por Producto"
                    outlined
                    hide-no-data
                    solo
                    @change='onSelectProduct'
                ></v-autocomplete>
            </v-col>
        </v-row>
        <v-row class="mb-3">
            <v-col cols="12" md="3">
                <div class="card-dashboard">
                    <div>
                        <h4 class="title-card">Venta promedio diario</h4>
                        <h4>{{ totales.total_dia | currency }}</h4>
                    </div>
                </div>
            </v-col>

            <v-col cols="12" md="3">
                <div class="card-dashboard">
                    <div>
                        <h4 class="title-card">Ventas del periodo</h4>
                        <h4>{{ totales.total_periodo | currency }}</h4>
                    </div>
                </div>
            </v-col>

            <v-col cols="12" md="3">
                <div class="card-dashboard">
                    <div>
                        <h4 class="title-card">Ticket promedio</h4>
                        <h4>{{ totales.ticket_promedio | currency }}</h4>
                    </div>
                </div>
            </v-col>

            <v-col cols="12" md="3">
                <div class="card-dashboard">
                    <div>
                        <h4 class="title-card">Medios de pago</h4>
                        <apexchart v-if="showDonut" type="donut" height="240" :options="donutBar.chartOptions"
                                   :series="donutBar.series"></apexchart>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="3">
                <div class="card-dashboard">
                    <div>
                        <h4 class="title-card">Total de transacciones</h4>
                        <h4>{{ totales.total_transacciones }}</h4>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="3">
                <div class="card-dashboard">
                    <div>
                        <h4 class="title-card">Total de unidades vendidas</h4>
                        <h4>{{ totales.total_cantidades }}</h4>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row class="mb-3">
            <v-col cols="12" md="12">
                <apexchart v-if="showLine" type="line" height="350" :options="line.chartOptions"
                           :series="line.series"></apexchart>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="card-dashboard-vendidos">
                    <div>
                        <h4 class="title-card">Mas Vendidos</h4>
                        <div>
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th class="text-left">
                                            Código
                                        </th>
                                        <th class="text-left">
                                            Producto
                                        </th>
                                        <th class="text-left">
                                            Importe
                                        </th>
                                        <th class="text-left">
                                            % ventas
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="item in ranking_productos">
                                        <td>{{ item.codigo_saco }}</td>
                                        <td>{{ item.description }}</td>
                                        <td>{{ item.total_linea | currency }}</td>
                                        <td>{{ item.sale_percentage }}%</td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </div>
                    </div>
                </div>
            </v-col>
            <v-col>
                <div class="card-dashboard-vendidos">
                    <div>
                        <h4 class="title-card">Categorías más vendidas</h4>
                        <div>
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th class="text-left">
                                            Categoría
                                        </th>
                                        <th class="text-left">
                                            Importe ventas
                                        </th>
                                        <th class="text-left">
                                            % ventas
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="item in ranking_categorias">
                                        <td>{{ item.nombre }}</td>
                                        <td>{{ item.total_linea | currency }}</td>
                                        <td>{{ item.sale_percentage }}%</td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Breadcrumbs from "@/components/layout/Breadcrumbs";
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from 'moment';
import pedidoService from "@/services/pedidoService";
import categoriaService from "@/services/categoriaService";
import marcaService from "@/services/marcaService";
import productService from "@/services/productService";

export default {
    name: 'Home',
    components: {
        Breadcrumbs,
        DateRangePicker
    },
    data() {
        return {
            breadcrumbs: [
                {text: 'Dashboard', disabled: false, href: '/',},
            ],
            overlay: false,
            snackbar: false,
            textError: '',
            dateRange: {
                startDate: null,
                endDate: null
            },
            categoriaId: null,
            marcaId: null,
            productoId: null,
            productSearch: null,
            search: null,
            products: [],
            isLoadingSearch: false,
            totales: {
                total_periodo: 0,
                total_transacciones: 0,
                ticket_promedio: 0,
                total_dia: 0,
                total_cantidades: 0,
            },
            categorias: [],
            marcas: [],
            showLine: false,
            showDonut: false,
            ranking_productos: [],
            ranking_categorias: [],
            picker: null,
            donutBar: {
                series: [],
                chartOptions: {
                    chart: {
                        // height: 350,
                        type: 'donut',
                    },
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }],
                    labels: ['Depósito', 'Pasarela'],
                },
            },
            line: {
                series: [{
                    name: "Ventas",
                    data: []
                }],
                chartOptions: {
                    chart: {
                        height: 350,
                        type: 'line',
                        zoom: {
                            enabled: false
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'straight'
                    },
                    title: {
                        text: 'Ventas hechas en el periodo',
                        align: 'left'
                    },
                    grid: {
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                    },
                    xaxis: {
                        categories: [],
                    }
                },
            }
        }
    },
    watch: {
        search(val) {
            // Items have already been loaded
            // if (this.tireTemplates.length > 0) return;

            // Items have already been requested
            if (this.isLoadingSearch) return;

            this.isLoadingSearch = true;
            // Lazily load input items
            productService.autocompleteSearch(val).then(response => {
                this.products = response.data.data;
                this.isLoadingSearch = false;
            }).catch(error => {
                this.isLoadingSearch = false;
                this.textError = error.response.data.message;
                this.snackbar = true;
            });
        },
    },
    mounted() {
        if (this.userSession.role == 'admin') {
            this.dateRange.startDate = moment(new Date()).subtract(1, 'month').toDate();
            this.dateRange.endDate = new Date();

            this.getData();
            categoriaService.all().then(response => {
                this.categorias = response.data.data;
                this.categorias.unshift({
                    'id': null,
                    'nombre': 'Todas las categorías',
                });
            }).catch(error => {
                this.textError = error.response.data.message;
                this.snackbar = true;
                console.error('error', error);
            });

            marcaService.all().then(response => {
                this.marcas = response.data.data;
                this.marcas.unshift({
                    'id': null,
                    'nombre': 'Todas las marcas',
                });
            }).catch(error => {
                this.textError = error.response.data.message;
                this.snackbar = true;
                console.error('error', error);
            });
        }

    },
    computed: {
        userSession: function () {
            return this.$store.getters.userSession
        },
    },
    methods: {
        onSelectProduct(e) {
            this.productSearch = null;
            this.search = null;
            this.productoId = e;
            this.getData();
        },
        getData() {
            this.showLine = false;
            this.showDonut = false;
            this.overlay = true;

            let fromDate = this.dateRange.startDate;
            let toDate = this.dateRange.endDate;
            if (moment.isMoment(fromDate)) {
                fromDate = fromDate.format('YYYY-MM-DD');
            } else {
                fromDate = moment(fromDate).format('YYYY-MM-DD')
            }
            if (moment.isMoment(toDate)) {
                toDate = toDate.format('YYYY-MM-DD');
            } else {
                toDate = moment(toDate).format('YYYY-MM-DD')
            }
            pedidoService.indicadoresPedidos({
                from_date: fromDate,
                to_date: toDate,
                category_id: this.categoriaId,
                marca_id: this.marcaId,
                product_id: this.productoId,
            }).then(response => {
                this.overlay = false;
                this.totales = response.data.data;
                this.line.series[0].data = response.data.data.line_chart.venta_fecha;
                this.line.chartOptions.xaxis.categories = response.data.data.line_chart.fechas;
                this.showLine = true;

                this.donutBar.series = [response.data.data.donut_bar.deposito, response.data.data.donut_bar.pasarela];
                this.showDonut = true;

                this.ranking_productos = response.data.data.ranking_productos;
                this.ranking_categorias = response.data.data.ranking_categorias;
            }).catch(error => {
                this.overlay = false;
                this.textError = error.response.data.message;
                this.snackbar = true;
                console.error('error', error);
            });
        }
    }
}
</script>

<style>
.card-dashboard {
    height: 250px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    box-shadow: 0px 3px 10px #D5D6D880;
    font-size: 1.1em;
}

.card-dashboard-vendidos {
    text-align: center;
    border-radius: 20px;
    box-shadow: 0px 3px 10px #D5D6D880;
    font-size: 1.1em;
    overflow: auto;
}

.title-card {
    color: #1371CB;
    margin-bottom: .5rem;
}

.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.reportrange-text {
    border: none !important;
}
</style>
