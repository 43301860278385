import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    searchClients(search) {
        return axios.get(`${ENDPOINT_PATH}/clients/search/${search}`);
    },
    uploadPhoto(image_base64) {
        const file = {file : image_base64};
        return axios.post(`${ENDPOINT_PATH}/pedido/upload-photo`, file);
    },
    uploadFile(formData) {
        return axios.post(`${ENDPOINT_PATH}/pedido/upload-file`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
    },
    pedidosDatatable(data) {
        return axios.post(`${ENDPOINT_PATH}/pedido/datatable`, data);
    },
    trackingDatatable(data) {
        return axios.post(`${ENDPOINT_PATH}/pedido/tracking/datatable`, data);
    },
    trackingDatatable2(data) {
        return axios.post(`${ENDPOINT_PATH}/pedido/tracking/datatable2`, data);
    },
    facturaDatatable(data) {
        return axios.post(`${ENDPOINT_PATH}/pedido/factura/datatable`, data);
    },
    reporteDatatable(data) {
        return axios.post(`${ENDPOINT_PATH}/pedido/reporte/datatable`, data);
    },
    pedidosWebDatatable(data) {
        return axios.post(`${ENDPOINT_PATH}/pedido/web/datatable`, data);
    },
    storePedido(data) {
        return axios.post(`${ENDPOINT_PATH}/pedido`, data);
    },
    updatePedido(data) {
        return axios.post(`${ENDPOINT_PATH}/pedido/update`, data);
    },
    updatePedidoWeb(data) {
        return axios.post(`${ENDPOINT_PATH}/pedido-web/update`, data);
    },
    validatePedidoWeb(data) {
        return axios.post(`${ENDPOINT_PATH}/pedido-web/validate`, data);
    },
    nullPedido(data) {
        return axios.post(`${ENDPOINT_PATH}/pedido/anular`, data);
    },
    nullPedidoWeb(data) {
        return axios.post(`${ENDPOINT_PATH}/pedido-web/anular`, data);
    },
    getPedido(pedido_id) {
        return axios.get(`${ENDPOINT_PATH}/pedido/${pedido_id}`);
    },
    getPedidoWeb(pedido_id) {
        return axios.get(`${ENDPOINT_PATH}/pedido-web/${pedido_id}`);
    },
    getDataTracking(pedido_id) {
        return axios.get(`${ENDPOINT_PATH}/pedido/tracking/${pedido_id}`);
    },
    getUbigeo(district_id) {
        return axios.get(`${ENDPOINT_PATH}/pedido/ubigeo/${district_id}`);
    },
    getUbigeoAgencia(district_id) {
        return axios.get(`${ENDPOINT_PATH}/pedido/ubigeo/agencia/${district_id}`);
    },
    downloadFileTest() {
        return axios.get(`${ENDPOINT_PATH}/downloadfile`,{ responseType: 'blob' });
    },
    downloadPedido(id) {
        return axios.get(`${ENDPOINT_PATH}/pedido/pdf/${id}`,{ responseType: 'blob' });
    },
    downloadXlsEquipos(id) {
        return axios.get(`${ENDPOINT_PATH}/pedido/xlsx_pedido/${id}`,{ responseType: 'blob' });
    },
    downloadPagado(id) {
        return axios.get(`${ENDPOINT_PATH}/pedido/pdf_pagado/${id}`,{ responseType: 'blob' });
    },
    downloadPagadoWeb(id) {
        return axios.get(`${ENDPOINT_PATH}/pedido/pdf_pagado_web/${id}`,{ responseType: 'blob' });
    },
    downloadXslxPedidos(data) {
        return axios.post(`${ENDPOINT_PATH}/pedido/export-xlsx`,data,{ responseType: 'blob' });
    },
    downloadXslxPedidosReport(data) {
        return axios.post(`${ENDPOINT_PATH}/pedido/export-xlsx/report`,data,{ responseType: 'blob' });
    },
    downloadTicketPedido(id) {
        return axios.get(`${ENDPOINT_PATH}/pedido/ticket-pdf/${id}`,{ responseType: 'blob' });
    },
    indicadoresPedidos(params) {
        return axios.get(`${ENDPOINT_PATH}/pedido/indicadores`,{ params: params });
    },
    getDataTrackingOlva(envio_emision,envio_codigo) {
        return axios.get(`https://reports.olvaexpress.pe/webservice/rest/getTrackingInformation?tracking=${envio_emision}&emision=${envio_codigo}&apikey=a82e5d192fae9bbfee43a964024498e87dfecb884b67c7e95865a3bb07b607dd&details=1`);
    },
    syncImeisBitel(id) {
        return axios.post(`${ENDPOINT_PATH}/pedido/sync/bitel/${id}`);
    },
    getImageOlva(envio_emision,envio_codigo) {
        //https://reports.olvaexpress.pe/webservice/rest/images?type=1&id=88929627&apikey=a82e5d192fae9bbfee43a964024498e87dfecb884b67c7e95865a3bb07b607dd
        //https://www.olvacourier.com/olva-apis/public/api/v1/images/${envio_emision}/${envio_codigo}?u=b2x2YV91c2VyX2ltYWdlcw==&p=VUY5V1R1aGRYMWlsMmlhOEN1UWNnVVFtYko1bHMwY1R3UG1UV1o0SlZiQWRvTVRZdFA=
        return axios.get(`https://www.olvacourier.com/olva-apis/public/api/v1/images/${envio_emision}/${envio_codigo}?u=b2x2YV91c2VyX2ltYWdlcw==&p=VUY5V1R1aGRYMWlsMmlhOEN1UWNnVVFtYko1bHMwY1R3UG1UV1o0SlZiQWRvTVRZdFA=`);
        //return axios.get(`https://reports.olvaexpress.pe/webservice/rest/images?type=1&id=88929627&apikey=a82e5d192fae9bbfee43a964024498e87dfecb884b67c7e95865a3bb07b607dd`);
    },
    getImageOlva2(id_envio) {
        //https://reports.olvaexpress.pe/webservice/rest/images?type=1&id=88929627&apikey=a82e5d192fae9bbfee43a964024498e87dfecb884b67c7e95865a3bb07b607dd
        //https://www.olvacourier.com/olva-apis/public/api/v1/images/${envio_emision}/${envio_codigo}?u=b2x2YV91c2VyX2ltYWdlcw==&p=VUY5V1R1aGRYMWlsMmlhOEN1UWNnVVFtYko1bHMwY1R3UG1UV1o0SlZiQWRvTVRZdFA=
        //return axios.get(`https://www.olvacourier.com/olva-apis/public/api/v1/images/${envio_emision}/${envio_codigo}?u=b2x2YV91c2VyX2ltYWdlcw==&p=VUY5V1R1aGRYMWlsMmlhOEN1UWNnVVFtYko1bHMwY1R3UG1UV1o0SlZiQWRvTVRZdFA=`);
        return axios.get(`https://reports.olvaexpress.pe/webservice/rest/images?type=1&id=${id_envio}&apikey=a82e5d192fae9bbfee43a964024498e87dfecb884b67c7e95865a3bb07b607dd`);
    },
    syncImeisFlexline(id) {
        return axios.post(`${ENDPOINT_PATH}/pedido/sync/flexline/${id}`);
    }
};
