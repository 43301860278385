import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    datatable(data) {
        return axios.post(`${ENDPOINT_PATH}/categorias/datatable`, data);
    },
    all() {
        return axios.get(`${ENDPOINT_PATH}/categorias`);
    },
    store(data) {
        return axios.post(`${ENDPOINT_PATH}/categorias`, data);
    },
    destroy(id) {
        return axios.delete(`${ENDPOINT_PATH}/categorias/${id}`);
    },
    update(data) {
        return axios.put(`${ENDPOINT_PATH}/categorias/${data.id}`, data);
    },
    show(id) {
        return axios.get(`${ENDPOINT_PATH}/categorias/${id}`);
    },
};
